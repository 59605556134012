<template>
    <div class="main analytics-page">
        <div class="main-width" v-if="isAuth">
            <div class="report-box" >
                <template v-if="!portrait">
                    <h1>{{ analytic && getName(analytic) }}</h1>
                    <router-link :to="{name: 'analytics'}" v-show="showReport" :class="{active: isActive}">
                        <i class="fas fa-times"></i>
                    </router-link>
                    
                    <div id="reportContainer" v-show="showReport" :class="{active: isActive}">
                    
                    </div>

                     <beat-loader 
                        v-if="loading" 
                        class="loader-center bi-loader" 
                        :color="'#391CAB'" 
                        :loading="loading" 
                        :size="20" 
                        :sizeUnit="'px'" 
                    />
                </template>

               <!-- Mobile portrait div  -->
                <div class="analytics-modal-area" v-else>
                    <div class="icon-area">
                        <img src="@/assets/img/icons/rotate.svg" alt="">
                    </div>  
                    <p class="text-center">{{ $t("AnalyticItem.rotate_screen") }}</p>
                </div>

            </div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as pbi from 'powerbi-client'


export default {
    name: 'Analytic-item',
    data: () => ({
        analytic: null,
        isActive: true,
        portrait: false,
        loading: true,
        pbiService: false,
        report: null,
        showReport: false

    }), 
    created() {
        this.handleOrientationChange();
    },
    mounted() {
        if (this.isAuth) {
            this.loading = this.showReport = true
            window.addEventListener("orientationchange",this.handleOrientationChange);
            this.getAnalytic(this.$route.params.id);
        } else {
            this.$router.push({name: 'analytics'})
        }
    },
    methods: {
        handleOrientationChange() {
            const orientation = window.screen.orientation.type;
            let f = document.querySelector('iframe');
            if (orientation === "portrait-primary") {
                this.portrait = true;
                this.isActive = false;
                if (f) {
                    f.style.display = 'none'
                }
            } else {
                this.portrait = false;
                this.isActive = this.showReport = true;
                if (f) {
                    f.style.display = 'block';
                } 
                // else {
                //     // условия когда reportContainer есть но отчет не отображается
                //     // метод заново делает запрос
                //     this.getAnalytic(this.$route.params.id);
                // }
            } 
        },
        embedPBI(containerId, accessToken, embedUrl, reportId) {
            var permissions = pbi.models.Permissions.All
            var config = {
                type: 'report',
                tokenType:  pbi.models.TokenType.Embed,
                accessToken: accessToken,
                embedUrl: embedUrl,
                id: reportId,
                permissions: permissions,
                settings: {
                    filterPaneEnabled: true,
                    navContentPaneEnabled: true
                }
            };

            this.pbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
            var reportContainer = document.getElementById(containerId);
            this.pbiService.reset(reportContainer);
            var report = this.pbiService.embed(reportContainer, config);

            report.on("loaded", function() {});

            report.off("rendered");

            report.on("error", function () {
                this.report.off("error");
            });
        },
        getAnalytic(id) {
            const params = {entity: "Analytics", uuid: id};
            this.$store.dispatch('CLICKED_LIST', params)
                .then( a => {
                    if (a.status == 200) {
                        this.analytic = a.data;
                        this.loading = true
                        if ( a.data.analyticsTariffList
                            .filter( t => t.dtariffId.id === this.tariff.id).length > 0 ) {
                            this.$store.dispatch('GET_PBI_REPORT', a.data.reportId)
                                .then( r => {
                                    if (r.status == 200) {
                                        this.report = r.data;
                                        this.loading = false;
                                        this.showReport = true;
                                        this.embedPBI('reportContainer', r.data.embedToken, r.data.embedUrl, r.data.reportId);
                                        this.refreshAccessToken();
                                    }
                                })
                                .catch(() => {
                                    this.loading = this.showReport = false;
                                    // this.$toast.error(this.$t('message.page_not_found'));
                                });
                        }
                        else {
                            this.loading = this.showReport = false;
                            this.$toast.error(this.$t("message.no_tariff_analytics"));
                        }
                    }
                })
                .catch(() => {
                    this.loading = this.showReport = false;
                    this.$toast.error(this.$t('message.page_not_found'));
                });
        },
        refreshAccessToken() {
            setInterval(()=> {
                const currentTime = Date.now();
                const expiration = Date.parse( this.report.expiration );
                const timeUntilExpiration = expiration - currentTime;
                const timeToUpdate = 10 * 60000; // min * 60sec * 1000millis

                if (timeUntilExpiration <= timeToUpdate) {
                    this.updateToken();
                }
            }, 5 * 60000);
        },
        updateToken() {
            this.$store.dispatch('GET_PBI_REPORT', this.report.reportId)
                .then( r => {
                    if (r.status == 200) {
                        this.report = r.data;
                        let report = this.pbiService.get(document.getElementById('reportContainer'));
                        report.setAccessToken(r.data.embedToken);
                    }
                })
                ;
        },
        getName(i) {
            let name;
            switch(this.$i18n.locale) {
                case 'kz':
                    name = i.nameKz;
                    break;
                default:
                    name = i.nameRu;
            }
            return name;
        },
    },
    computed: {
        ...mapGetters([
            'USER_TARIFF',
            'user_tarif_name',
            'user_tarif_id',
            'user',
            'isAuth'
        ]),
        tariff() {
            return this.USER_TARIFF;
        },
        analytics() {
            return this.items && this.items.filter( i => {
                if ( i.analyticsTariffList
                        .filter( t => t.dtariffId.id === this.tariff.id).length > 0) {
                        return true;
                    }
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.analytics-page {
    min-height: 50vh;
}
.report-box a.active  {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 10001
}


#reportContainer.active  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    padding: 50px 10px 10px;
    background: #fff;
    overflow: hidden;
}

.report-box .bi-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001;
}

.analytics-modal-area {
    height: 200px !important;
    max-width: 300px;
    margin: 0 auto;
}

.icon-area {
    width: 100px;
    height: auto;
    margin: 20px auto;
}

.icon-area img {
    max-width: 100%;
}

p.text-center {
    width: 80%;
    margin: 0 auto;
}
.report-box i {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: .5;
}

</style>